<template>
  <v-alert
      border="left"
      colored-border
      elevation="2"
      color="primary"
      dismissible
      v-if="alert"
    >
      Lo sai? Usa il codice <span class="primary--text">WELCOME50</span> per ottenere il 50% di sconto sull’abbonamento a TechSoup Together. Applicalo al checkout entro il 7 febbraio
    </v-alert>
</template>

<script>
import moment from 'moment'

export default {

  name: "AlertPromotions",

  data: () => ({
    // alert: true,
  }),

  computed: {
    alert(){
      let a = false
      if(moment().isBefore('2021-02-08')){
        a = true
      }
      return a
    }
  },

  created(){
  }

}
</script>

<style>

</style>